<template>
  <div
    class="untopped-order-list border-radius-6 box-shadow-light-grey padding-20 height-calc-type1"
  >
    <dy-table
      :dataList="dataList"
      :rowList="rowList"
      :showPagination="true"
      :page="page"
      :loading="loading"
      :pageSize="pageSize"
      :total="total"
      height="66.3vh"
      @pageChange="pageChange"
      @selection-change="selectChange"
    >
      <template #filter>
        <div style="line-height:40px">
          <el-button type="success" @click="refreshData" style="margin-right:10px">刷新数据</el-button>
          <el-select
            class="margin-right-ten"
            style="width: 140px;margin-right:10px"
            v-model="params.platformId"
            value-key="id"
            filterable
            placeholder="平台"
            :clearable="true"
          >
            <el-option
              v-for="item in messagePlatformListDetail"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <!-- <el-select
            class="margin-right-ten"
            v-model="currentOfficial"
            placeholder="请选择公众号"
            style="width: 140px;margin-right:10px"
            @change="initData"
            filterable
            clearable
          >
            <el-option
              v-for="item in getFilterList()"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span
                style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span>
            </el-option>
          </el-select> -->
          <c-input v-model="nickname" placeholder="昵称" style="width:100px;margin-right:10px"></c-input>
          <c-input v-model="originNovel" placeholder="来源小说" style="width:150px;margin-right:10px"></c-input>
          <el-date-picker
            v-model="params.startRegisterTime"
            type="datetime"
            style="margin-right: 10px;width:150px"
            placeholder="注册开始时间"
          />
          <el-date-picker
            v-model="params.endRegisterTime"
            style="margin-right: 10px;width:150px"
            type="datetime"
            placeholder="注册结束时间"
          ></el-date-picker>
          <c-input
            :onlyNum="true"
            v-model="params.minAmount"
            placeholder="最小金额"
            style="width:120px;margin-right:10px"
          ></c-input>
          <c-input
            :onlyNum="true"
            v-model="params.maxAmount"
            placeholder="最大金额"
            style="width:120px;margin-right:10px"
          ></c-input>
          <el-button type="success" @click="initData">查询</el-button>
          <el-button type="warning" @click="resetParams">重置</el-button>
        </div>
      </template>
      <template #accountChannelId="{ row }">
        <span>{{ getAccountName(row.accountChannelId) }}</span>
      </template>
    </dy-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { unPaidUserList } from '@/api/classify'
import {
  messagePlatformListDetail,
  messagePlatformList,
  pickerOptions
} from '@/assets/js/options'
import moment from 'moment'
export default {
  props: ['accountChannelId', 'relationId','currentOfficial'],
  data () {
    return {
      page: 1,
      total: 0,
      params: {},
      nickname: '',
      pageSize: 15,
      dataList: [],
      pickerOptions,
      originNovel: '',
      loading: false,
      // currentOfficial: null,
      messagePlatformListDetail,
      rowList: [
        {
          label: '公众号',
          prop: 'accountChannelId',
          slot: true
        },
        {
          label: '来源小说',
          prop: 'originNovel'
        },
        {
          label: '昵称',
          prop: 'nickname'
        },
        {
          label: '备注',
          prop: 'originRemark'
        },
        {
          label: '订单金额',
          prop: 'amount'
        },
        {
          label: '下单时间',
          prop: 'orderCreatedTime'
        },
        {
          label: '注册时间',
          prop: 'registerTime'
        },
        {
          label: 'openId',
          prop: 'openId',
          width: '150'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['officialList', 'userInfo']),
    channelList () {
      const { officialList } = this
      return officialList.filter(
        item => messagePlatformList.includes(item.platform) && item.status === 1
      )
    }
  },
  watch: {
  },
  // mounted () {
  //   this.initData()
  // },
  methods: {
    initData () {
      this.loading = true
      unPaidUserList({
        selection: [],
        page: this.page,
        nickname: this.nickname,
        pageSize: this.pageSize,
        originNovel: this.originNovel,
        platform: this.params.platformId,
        accountChannelId: this.currentOfficial,
        endRegisterTime: this.params.endRegisterTime ? moment(this.params.endRegisterTime).format('YYYY-MM-DD HH:mm:ss') : null,
        startRegisterTime: this.params.startRegisterTime ? moment(this.params.startRegisterTime).format('YYYY-MM-DD HH:mm:ss') : null,
        minAmount: this.params.minAmount || null,
        maxAmount: this.params.maxAmount || null
      }).then(res => {
        this.total = res.total
        this.dataList = res.list
      }).finally(() => {
        this.loading = false
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    selectChange (select) {
      this.selection = select
    },
    getAccountName (id) {
      return this.channelList.find(item => item.id === id).channelName
    },
    getFilterList () {
      return (this.params.platformId === undefined || this.params.platformId === null) ? this.channelList : this.channelList.filter(item => item.platform === this.params.platformId)
    },
    refreshData () {
      this.page = 1

      this.initData()
    },
    resetParams () {
      this.params = {}
      this.currentOfficial = null
      this.originNovel = ''
      this.nickname = ''
      this.page = 1
      this.initData()
    }
  },
  components: {

  }
}
</script>
<style lang='scss' scoped>
.untopped-order-list {
  background: #fff;
}
</style>
